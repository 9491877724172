import React, { useState, useEffect } from 'react'
import '../../static/styles/capture-form.css'
import '../../static/styles/course-banner.css'

const darkImgURL =
  'https://imgix.cosmicjs.com/574b3e00-66fd-11eb-9443-d15245f849b1-image4-9.jpg'

const lightImgUrl =
  'https://imgix.cosmicjs.com/51ecbb00-66fd-11eb-9443-d15245f849b1-AdobeStock275716975-1024x683.jpeg'

export default ({ isForm, body, buttonText, formUrl }) => {
  if (!body || !buttonText || !formUrl) {
    return <div></div>
  }

  // form variables
  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formPassword, setFormPassword] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    // making the callback function global so that it could be recognized by g-recaptcha
    window.onChange = onChange
    // executing recaptcha
    if (!grecaptcha.ready) {
      grecaptcha.ready(function () {
        grecaptcha.render('g-recaptcha', {
          sitekey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
        })
      })
    }

    grecaptcha.execute()
  }

  // this function only runs if the recaptcha challenge is successful
  function onChange() {
    // submitting the form
    document.getElementById('custom-cta').submit()
  }

  // load ecaptcha only when needed. Recaptcha has a massive performance impact
  function loadReCaptcha() {
    var head = document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.google.com/recaptcha/api.js'
    head.appendChild(script)
  }

  return (
    <div id="course-banner" className="form-banner-container">
      <div
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
      <div className="d-flex flex-column justify-content-center align-items-center text-center">
        <form
          method="post"
          action={formUrl}
          className="sendfox-form"
          id="custom-cta"
          data-async="true"
          // disable sendfox's recaptcha
          data-recaptcha="false"
          onSubmit={onSubmit}
        >
          <div
            data-sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            data-callback="onChange"
            className="g-recaptcha"
            data-size="invisible"
          ></div>
          <p>
            <input
              type="text"
              placeholder="First Name"
              name="first_name"
              id="first_name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              onFocus={(e) => loadReCaptcha()}
              className="form-control"
              required
            />
          </p>
          <p>
            <input
              id="email"
              type="email"
              placeholder="Email"
              name="email"
              value={formEmail}
              onChange={(e) => setFormEmail(e.target.value)}
              onFocus={(e) => loadReCaptcha()}
              required
              className="form-control"
            />
          </p>
          <div
            aria-hidden="true"
            style={{
              position: 'absolute',
              left: '-5000px',
            }}
          >
            <input
              id="a_password"
              value={formPassword}
              onChange={(e) => setFormPassword(e.target.value)}
              type="text"
              name="a_password"
              className="form-control"
              tabIndex="-1"
              value=""
              autoComplete="off"
            />
          </div>
          <input
            type="submit"
            className="btn btn-primary purple-btn"
            value={buttonText}
          />
          <script src="https://sendfox.com/js/form.js"></script>
        </form>
        <div className="recaptcha-text">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="nofollow"
          >
            {' '}
            Privacy Policy
          </a>{' '}
          and
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="nofollow"
          >
            {' '}
            Terms of Service
          </a>{' '}
          apply.
        </div>
      </div>
    </div>
  )
}
