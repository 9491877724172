import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '@fontsource/open-sans/400-italic.css'
import RichTextRenderer from 'utils/RichTextRenderer'
import { storyblokEditable } from "gatsby-source-storyblok"
import Seo from "components/Seo"

import '../../static/styles/plain-page.css'
import CustomCta from '../components/CustomCta'

const PlainPage = ({ data, pageContext, path }) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  const story = useStoryblokState(data.page);

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${data.page.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  // const formAction = get(guide, 'metadata.campaign_optin_url', null)

  const cta_form = get(content, 'cta_form', true)
  const cta_body = get(content, 'cta_body', null)
  const cta_button_text = get(content, 'cta_button_text', null)
  const cta_url = get(content, 'cta_url', null)

  return (
    <>
      <Seo
        title={`${story.name} | QuickMail`}
        url={data.page.slug}
      />
      <div
        className="container padded p-relative plain-page"
        style={{ zIndex: '10' }}
      >
        <section {...storyblokEditable(content)} className="row" id={pageContext['slug']}>
          <div className="col-12 col-lg-10 col-xl-8 mx-auto">
            <div className="page-content">
              <RichTextRenderer data={content.content} />
            </div>
            <CustomCta
              isForm={cta_form}
              body={cta_body}
              buttonText={cta_button_text}
              formUrl={cta_url}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default PlainPage

export const PlainPageQuery = graphql`
  query PlainPageBySlug($slug: String!) {
    page: storyblokEntry(slug: { eq: $slug }) {
      name
      content
      slug
    }
  }
`
